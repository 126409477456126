<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div id="register-form">
    <!--    <div class="taskbar_admissions">-->
    <!--      <div>-->
    <!--        <b-img-->
    <!--          src="@/assets/images/logo/logo-mis-1.png"-->
    <!--          height="50px"-->
    <!--        />-->
    <!--      </div>-->
    <!--      <div class="d-flex align-items-center mr-2">-->
    <!--        <span class="text_box font-medium-5 mr-2 d-lg-block d-none">-->
    <!--          Xin chào phụ huynh học sinh-->
    <!--        </span>-->
    <!--        <b-button-->
    <!--          variant="primary"-->
    <!--          @click=""-->
    <!--        >-->
    <!--          Đăng ký nhận thông tin-->
    <!--        </b-button>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="add-student-body px-2">
      <div class="w-100 py-2">
        <b-row>
          <b-col class="col-md-8 col-12 mx-auto">
            <b-card>
              <h2 class="brand-text text-primary text-main-header text-center">
                Đăng ký hồ sơ tuyển sinh
              </h2>
              <validation-observer ref="modelStudent">
                <b-row class="mt-3">
                  <b-col class="col-12 d-flex justify-content-center">
                    <upload-image :images="images" />
                  </b-col>
                </b-row>
                <b-row class="mt-5">
                  <b-col class="col-lg-3 col-sm-6 col-12">
                    <h6>Chọn cấp<span class="text-danger"> (*)</span></h6>
                    <validation-provider #default="{ errors }" name="Cấp học" rules="required">
                      <b-form-group :state="errors.length > 0 ? false : null">
                        <v-select v-model="modelStudent.levelId" placeholder="Chọn cấp học" label="name"
                                  :disabled="checkLevel" :reduce="(option) => option.id" :options="optionsLevelSchool"
                                  @input="getListClass(modelStudent.levelId)"
                        >
                          <span slot="no-options">Không có dữ liệu.</span>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <div id="student-info" class="header-info"
                     style="border-bottom: 1px solid #d8d6de; margin-bottom: 2rem"
                >
                  <h4>THÔNG TIN HỌC SINH</h4>
                  <b-row>
                    <b-col class="col-lg-3 col-sm-6 col-12">
                      <validation-provider #default="{ errors }" name="Họ" rules="required">
                        <b-form-group>
                          <h6 for="lastName">
                            Họ<span class="text-danger">(*)</span>
                          </h6>
                          <b-form-input id="lastName" v-model="modelStudent.lastName"
                                        :state="errors.length > 0 ? false : null" type="text" class="form-control"
                                        placeholder="Nhập họ" @input="getFullName"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col class="col-lg-3 col-sm-6 col-12">
                      <validation-provider #default="{ errors }" name="Tên" rules="required">
                        <b-form-group>
                          <h6 for="firstName">
                            Tên<span class="text-danger">(*)</span>
                          </h6>
                          <b-form-input id="firstName" v-model="modelStudent.firstName"
                                        :state="errors.length > 0 ? false : null" type="text" class="form-control"
                                        placeholder="Nhập tên" @input="getFullName"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col class="col-lg-3 col-sm-6 col-12">
                      <validation-provider #default="{ errors }" name="Họ và tên" rules="required">
                        <b-form-group>
                          <h6 for="fullName">
                            Họ và tên<span class="text-danger">(*)</span>
                          </h6>
                          <b-form-input id="fullName" v-model="modelStudent.fullName"
                                        :state="errors.length > 0 ? false : null" type="text" class="form-control"
                                        disabled
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col class="col-lg-3 col-sm-6 col-12">
                      <validation-provider #default="{ errors }" name="Ngày sinh" rules="required">
                        <b-form-group>
                          <h6>Ngày sinh<span class="text-danger">
                            (*)</span></h6>
                          <flat-pickr v-model="modelStudent.birthDay" :state="errors.length > 0 ? false : null"
                                      class="form-control" :config="configDOBFlatpickr" placeholder="Chọn ngày sinh"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!--                    <b-col class="col-lg-3 col-sm-6 col-12">-->
                    <!--                      <validation-provider #default="{ errors }" name="Số điện thoại "-->
                    <!--                                           :rules="{ required: true, regex: /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/ }"-->
                    <!--                      >-->
                    <!--                        <b-form-group>-->
                    <!--                          <h6 for="phone">Số điện thoại liên hệ chính<span class="text-danger"> (*)</span>-->
                    <!--                          </h6>-->
                    <!--                          <b-form-input id="phone" v-model="modelStudent.phoneNumber"-->
                    <!--                                        :state="errors.length > 0 ? false : null" type="number" class="form-control"-->
                    <!--                                        placeholder="Nhập số điện thoại"-->
                    <!--                          />-->
                    <!--                          <small class="text-danger">{{ errors[0] }}</small>-->
                    <!--                        </b-form-group>-->
                    <!--                      </validation-provider>-->
                    <!--                    </b-col>-->
                    <!--                    <b-col class="col-lg-3 col-sm-6 col-12">-->
                    <!--                      <validation-provider #default="{ errors }" name="Email " rules="email|required">-->
                    <!--                        <b-form-group>-->
                    <!--                          <h6 for="email">-->
                    <!--                            Email phụ huynh <span class="text-danger">(*)</span>-->
                    <!--                          </h6>-->
                    <!--                          <b-form-input id="email" v-model="modelStudent.email" :state="errors.length > 0 ? false : null"-->
                    <!--                                        type="text" class="form-control" placeholder="Nhập email"-->
                    <!--                          />-->
                    <!--                          <small class="text-danger">{{ errors[0] }}</small>-->
                    <!--                        </b-form-group>-->
                    <!--                      </validation-provider>-->
                    <!--                    </b-col>-->
                    <b-col class="col-lg-3 col-sm-6 col-12">
                      <h6>Giới tính<span class="text-danger"> (*)</span></h6>
                      <validation-provider #default="{ errors }" name="Giới tính" rules="required">
                        <b-form-group :state="errors.length > 0 ? false : null">
                          <v-select v-model="modelStudent.gender" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="text" placeholder="Chọn giới tính" :options="sexOptions"
                                    :reduce="(option) => option.value"
                          >
                            <span slot="no-options">Không có dữ liệu.</span>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col class="col-lg-3 col-sm-6 col-12">
                      <b-form-group>
                        <h6>Dân tộc</h6>
                        <b-form-input v-model="modelStudent.ethnic" type="text" class="form-control"
                                      placeholder="Nhập dân tộc"
                        />
                      </b-form-group>
                    </b-col>
<!--                    <b-col class="col-lg-3 col-sm-6 col-12">-->
<!--                      <h6>Tỉnh/ thành phố</h6>-->
<!--                      <validation-provider #default="{ errors }" name="Tỉnh/ thành phố">-->
<!--                        <b-form-group :state="errors.length > 0 ? false : null">-->
<!--                          <v-select v-model="modelStudent.province" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
<!--                                    label="label" placeholder="Chọn tỉnh/ thành phố" :options="cityOptions"-->
<!--                                    :reduce="(option) => option.value" @input="getListDistrict"-->
<!--                          >-->
<!--                            <span slot="no-options">Không có dữ liệu.</span>-->
<!--                          </v-select>-->
<!--                          <small class="text-danger">{{ errors[0] }}</small>-->
<!--                        </b-form-group>-->
<!--                      </validation-provider>-->
<!--                    </b-col>-->
<!--                    <b-col class="col-lg-3 col-sm-6 col-12">-->
<!--                      <h6>Quận/ huyện</h6>-->
<!--                      <validation-provider #default="{ errors }" name="Quận huyện">-->
<!--                        <b-form-group :state="errors.length > 0 ? false : null">-->
<!--                          <v-select v-model="modelStudent.district" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
<!--                                    label="label" placeholder="Chọn quận huyện" :options="districtOptions"-->
<!--                                    :reduce="(option) => option.value" @input="getListSubDistrict"-->
<!--                          >-->
<!--                            <span slot="no-options">Không có dữ liệu.</span>-->
<!--                          </v-select>-->
<!--                          <small class="text-danger">{{ errors[0] }}</small>-->
<!--                        </b-form-group>-->
<!--                      </validation-provider>-->
<!--                    </b-col>-->
<!--                    <b-col class="col-lg-3 col-sm-6 col-12">-->
<!--                      <h6>Xã/ phường</h6>-->
<!--                      <validation-provider #default="{ errors }" name="Xã phường">-->
<!--                        <b-form-group :state="errors.length > 0 ? false : null">-->
<!--                          <v-select v-model="modelStudent.subDistrict"-->
<!--                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
<!--                                    label="label" placeholder="Chọn xã phường" :reduce="(option) => option.value"-->
<!--                                    :options="subDistrictOptions" @input="changeSubDistrict"-->
<!--                          >-->
<!--                            <span slot="no-options">Không có dữ liệu.</span>-->
<!--                          </v-select>-->
<!--                          <small class="text-danger">{{ errors[0] }}</small>-->
<!--                        </b-form-group>-->
<!--                      </validation-provider>-->
<!--                    </b-col>-->
<!--                    <b-col class="col-lg-3 col-sm-6 col-12">-->
<!--                      <div class="form-group">-->
<!--                        <h6 for="address">-->
<!--                          Số nhà-->
<!--                        </h6>-->
<!--                        <input id="address" v-model="modelStudent.houseNumber" type="text" class="form-control"-->
<!--                               placeholder="Nhập số nhà" @input="changeAddress"-->
<!--                        >-->
<!--                      </div>-->
<!--                    </b-col>-->
                    <b-col class="col-md-6 col-12">
                      <div class="form-group">
                        <h6 for="addressCurrent">
                          Nơi ở hiện tại
                        </h6>
                        <input id="addressCurrent" v-model="modelStudent.address" type="text" class="form-control"
                        >
                      </div>
                    </b-col>
                    <b-col class="col-lg-3 col-sm-6 col-12">
                      <div class="form-group">
                        <h6 for="address">
                          Cân nặng (kg)
                        </h6>
                        <input id="weight" v-model="modelStudent.weight" type="text" class="form-control"
                               placeholder="Nhập cân nặng"
                        >
                      </div>
                    </b-col>
                    <b-col class="col-lg-3 col-sm-6 col-12">
                      <div class="form-group">
                        <h6 for="address">
                          Chiều cao (cm)
                        </h6>
                        <input id="Height" v-model="modelStudent.height" type="text" class="form-control"
                               placeholder="Nhập chiều cao"
                        >
                      </div>
                    </b-col>
                    <b-col class="col-lg-3 col-sm-6 col-12">
                      <div class="form-group">
                        <h6 for="studentEducationNumber">
                          Mã định danh Bộ GD
                        </h6>
                        <input id="studentEducationNumber" v-model="modelStudent.studentEducationNumber" type="text"
                               class="form-control"
                               placeholder="Nhập mã"
                        >
                      </div>
                    </b-col>
                    <b-col class="col-lg-3 col-sm-6 col-12">
                      <div class="form-group">
                        <h6 for="studentIdentificationNumber">
                          Mã định danh cá nhân
                        </h6>
                        <input id="studentIdentificationNumber" v-model="modelStudent.studentIdentificationNumber"
                               type="text" class="form-control"
                               placeholder="Nhập mã"
                        >
                      </div>
                    </b-col>
                    <b-col class="col-md-6 col-12">
                      <b-row>
                        <b-col class="col-md-12 col-12">
                          <div class="form-group">
                            <h6>Dự định đi du học</h6>
                            <div class="mt-1">
                              <b-form-radio-group v-model="modelStudent.studyAboard" :options="optionStudyAbroad"
                                                  name="study-abroad" class="mt-0" value-field="value" text-field="text"
                              />
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col class="col-md-12 col-12">
<!--                      <b-row>-->
<!--                        <b-col class="col-lg-3 col-sm-6 col-12">-->
<!--                          <div class="form-group">-->
<!--                            <h6 for="address">-->
<!--                              Thời gian liên lạc-->
<!--                            </h6>-->
<!--                            <input id="contactTime" v-model="modelStudent.contactTime" type="text" class="form-control"-->
<!--                                   placeholder="Nhập thời gian liên lạc"-->
<!--                            >-->
<!--                          </div>-->
<!--                        </b-col>-->
<!--                      </b-row>-->
                      <b-row>
                        <b-col v-if="modelStudent.levelId === 1" class="col-md-12 mt-1 col-12">
                          <div id="checkbox-level1" class="form-group mb-0" @change="getPrice(modelStudent.classEntity)">
                            <div class="css-checkbox-level mt-1">
                              <h6 class="mr-1">
                                Lớp 1
                              </h6>
                              <div class="demo-inline-spacing">
                                <b-form-radio-group v-model="modelStudent.classEntity" :options="optionsLevel1"
                                                    class="mt-0"
                                                    value-field="value" text-field="text"
                                />
                              </div>
                            </div>
                            <div class="css-checkbox-level mt-1">
                              <h6 class="mr-1">
                                Lớp 2
                              </h6>
                              <div class="demo-inline-spacing">
                                <b-form-radio-group v-model="modelStudent.classEntity" :options="optionsLevel2"
                                                    class="mt-0"
                                                    value-field="value" text-field="text"
                                />
                              </div>
                            </div>
                            <div class="css-checkbox-level mt-1">
                              <h6 class="mr-1">
                                Lớp 3
                              </h6>
                              <div class="demo-inline-spacing">
                                <b-form-radio-group v-model="modelStudent.classEntity" :options="optionsLevel3"
                                                    class="mt-0"
                                                    value-field="value" text-field="text"
                                />
                              </div>
                            </div>
                            <div class="css-checkbox-level mt-1">
                              <h6 class="mr-1">
                                Lớp 4
                              </h6>
                              <div class="demo-inline-spacing">
                                <b-form-radio-group v-model="modelStudent.classEntity" :options="optionsLevel4"
                                                    class="mt-0"
                                                    value-field="value" text-field="text"
                                />
                              </div>
                            </div>
                            <div class="css-checkbox-level mt-1">
                              <h6 class="mr-1">
                                Lớp 5
                              </h6>
                              <div class="demo-inline-spacing">
                                <b-form-radio-group v-model="modelStudent.classEntity" :options="optionsLevel5"
                                                    class="mt-0"
                                                    value-field="value" text-field="text"
                                />
                              </div>
                            </div>
                          </div>
                        </b-col>
                        <b-col v-if="modelStudent.levelId === 2" class="col-md-12 mt-1 col-12">
                          <div id="checkbox-level2" class="form-group mb-0" @change="getPrice(modelStudent.classEntity)">
                            <div class="css-checkbox-level">
                              <h6 class="mr-1">
                                Lớp 6
                              </h6>
                              <div class="demo-inline-spacing">
                                <b-form-radio-group v-model="modelStudent.classEntity" :options="optionsLevel6"
                                                    class="mt-0"
                                                    value-field="value" text-field="text"
                                />
                              </div>
                            </div>
                            <div class="css-checkbox-level mt-1">
                              <h6 class="mr-1">
                                Lớp 7
                              </h6>
                              <div class="demo-inline-spacing">
                                <b-form-radio-group v-model="modelStudent.classEntity" :options="optionsLevel7"
                                                    class="mt-0"
                                                    value-field="value" text-field="text"
                                />
                              </div>
                            </div>
                            <div class="css-checkbox-level mt-1">
                              <h6 class="mr-1">
                                Lớp 8
                              </h6>
                              <div class="demo-inline-spacing">
                                <b-form-radio-group v-model="modelStudent.classEntity" :options="optionsLevel8"
                                                    class="mt-0"
                                                    value-field="value" text-field="text"
                                />
                              </div>
                            </div>
                            <div class="css-checkbox-level mt-1">
                              <h6 class=" mr-1">
                                Lớp 9
                              </h6>
                              <div class="demo-inline-spacing">
                                <b-form-radio-group v-model="modelStudent.classEntity" :options="optionsLevel9"
                                                    class="mt-0"
                                                    value-field="value" text-field="text"
                                />
                              </div>
                            </div>
                          </div>
                        </b-col>
                        <b-col v-if="modelStudent.levelId === 3" class="col-md-12 mt-1 col-12">
                          <div id="checkbox-level3" class="form-group mb-0" @change="getPrice(modelStudent.classEntity)">
                            <div class="css-checkbox-level">
                              <h6 class="mr-1">
                                Lớp 10
                              </h6>
                              <div class="demo-inline-spacing">
                                <b-form-radio-group v-model="modelStudent.classEntity" :options="optionsLevel10"
                                                    class="mt-0" value-field="value" text-field="text"
                                />
                              </div>
                            </div>
                            <div class="css-checkbox-level mt-1">
                              <h6 class="mr-1">
                                Lớp 11
                              </h6>
                              <div class="demo-inline-spacing">
                                <b-form-radio-group v-model="modelStudent.classEntity" :options="optionsLevel11"
                                                    class="mt-0" value-field="value" text-field="text"
                                />
                              </div>
                            </div>
                            <div class="css-checkbox-level mt-1">
                              <h6 class="mr-1">
                                Lớp 12
                              </h6>
                              <div class="demo-inline-spacing">
                                <b-form-radio-group v-model="modelStudent.classEntity" :options="optionsLevel12"
                                                    class="mt-0" value-field="value" text-field="text"
                                />
                              </div>
                            </div>
                          </div>
                        </b-col>
                        <!--                        <b-col v-if="modelStudent.levelId" class="col-md-12 mt-1 col-12">-->
                        <!--                          <div class="form-group mb-0">-->
                        <!--                            <div class="css-checkbox-level mt-1" @change="getGrade(modelStudent.grade)">-->
                        <!--                              <h6 class="mr-1">-->
                        <!--                                Chọn lớp-->
                        <!--                              </h6>-->
                        <!--                              <div class="demo-inline-spacing">-->
                        <!--                                <b-form-radio-group v-if="modelStudent.levelId === 1" v-model="modelStudent.grade"-->
                        <!--                                                    :options="level1Options"-->
                        <!--                                                    class="mt-0"-->
                        <!--                                                    value-field="value" text-field="label"-->
                        <!--                                />-->
                        <!--                                <b-form-radio-group v-if="modelStudent.levelId === 2" v-model="modelStudent.grade"-->
                        <!--                                                    :options="level2Options"-->
                        <!--                                                    class="mt-0"-->
                        <!--                                                    value-field="value" text-field="label"-->
                        <!--                                />-->
                        <!--                                <b-form-radio-group v-if="modelStudent.levelId === 3" v-model="modelStudent.grade"-->
                        <!--                                                    :options="level3Options"-->
                        <!--                                                    class="mt-0"-->
                        <!--                                                    value-field="value" text-field="label"-->
                        <!--                                />-->
                        <!--                              </div>-->
                        <!--                            </div>-->
                        <!--                          </div>-->
                        <!--                        </b-col>-->
                      </b-row>
                    </b-col>
                  </b-row>
<!--                  <b-row v-if="modelStudent.levelId === 4">-->
<!--                    <b-col class="col-lg-3 col-sm-6 col-12">-->
<!--                      <div class="form-group">-->
<!--                        <h6 for="address">-->
<!--                          Thời gian liên lạc-->
<!--                        </h6>-->
<!--                        <input id="contactTime" v-model="modelStudent.contactTime" type="text" class="form-control"-->
<!--                               placeholder="Nhập thời gian liên lạc"-->
<!--                        >-->
<!--                      </div>-->
<!--                    </b-col>-->
<!--                  </b-row>-->
                </div>
                <div id="family-info" class="header-info" style="border-bottom: 1px solid #d8d6de; margin-bottom: 2rem">
                  <h4>THÔNG TIN GIA ĐÌNH<span class="text-danger">(*)</span></h4>
                  <!--                <b-row>-->
                  <!--                  <b-col>-->
                  <!--                    <div class="form-group">-->
                  <!--                      <h6 class="mr-1">-->
                  <!--                        Học sinh đang sinh sống với:-->
                  <!--                      </h6>-->
                  <!--                      <div class="demo-inline-spacing">-->
                  <!--                        <b-form-checkbox-group v-model="modelStudent.livingWith" :options="optionLivingWith"-->
                  <!--                                               class="mt-0"-->
                  <!--                                               value-field="value" text-field="text"-->
                  <!--                        />-->
                  <!--                      </div>-->
                  <!--                    </div>-->
                  <!--                  </b-col>-->
                  <!--                </b-row>-->
                  <b-row>
                    <b-col class="col-12">
                      <h5>- Bố:</h5>
                    </b-col>
                    <b-col class="col-md-4 col-12">
                      <div class="form-group">
                        <label class="label-add-student" for="nameFather">
                          Họ và tên
                        </label>
                        <input id="nameFather" v-model="modelStudent.fatherName" type="text" class="form-control"
                               placeholder="Nhập họ tên bố"
                        >
                      </div>
                    </b-col>
                    <b-col class="col-md-4 col-12">
                      <validation-provider #default="{ errors }"
                                           :rules="{ regex: /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/ }"
                                           name="Số điện thoại"
                      >
                        <b-form-group>
                          <label class="label-add-student" for="phoneFather">Số điện thoại</label>
                          <b-form-input id="phoneFather" v-model="modelStudent.fatherNumber"
                                        :state="errors.length > 0 ? false : null" type="text" class="form-control"
                                        placeholder="Nhập số điện thoại bố"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col class="col-md-4 col-12">
                      <validation-provider #default="{ errors }" name="Email" rules="email">
                        <b-form-group>
                          <label class="label-add-student" for="fatherEmail">Email</label>
                          <b-form-input id="fatherEmail" v-model="modelStudent.fatherEmail"
                                        :state="errors.length > 0 ? false : null" type="text" class="form-control"
                                        placeholder="Nhập email"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col class="col-md-4 col-12">
                      <validation-provider #default="{ errors }" name="Ngày sinh">
                        <b-form-group>
                          <label class="label-add-student" for="fatherBirthday">Ngày sinh</label>
                          <b-form-group>
                            <flat-pickr id="fatherBirthday" v-model="modelStudent.fatherBirthday" class="form-control"
                                        :config="configDOBFlatpickr"
                                        placeholder="Chọn ngày sinh"
                                        :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col class="col-md-4 col-12">
                      <validation-provider #default="{ errors }" name="Công việc">
                        <b-form-group>
                          <label class="label-add-student" for="fatherJob">Công việc</label>
                          <b-form-input id="fatherJob" v-model="modelStudent.fatherJob"
                                        :state="errors.length > 0 ? false : null" type="text" class="form-control"
                                        placeholder="Nhập công việc"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col class="col-md-4 col-12">
                      <validation-provider #default="{ errors }" name="Nơi công tác">
                        <b-form-group>
                          <label class="label-add-student" for="fatherJobPosition">Nơi công tác</label>
                          <b-form-input id="fatherJobPosition" v-model="modelStudent.fatherJobPosition"
                                        :state="errors.length > 0 ? false : null" type="text" class="form-control"
                                        placeholder="Nhập nơi công tác"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="col-12">
                      <h5>- Mẹ:</h5>
                    </b-col>
                    <b-col class="col-md-4 col-12">
                      <div class="form-group">
                        <label class="label-add-student" for="nameMother">
                          Họ và tên
                        </label>
                        <input id="nameMother" v-model="modelStudent.motherName" type="text" class="form-control"
                               placeholder="Nhập họ tên mẹ"
                        >
                      </div>
                    </b-col>
                    <b-col class="col-md-4 col-12">
                      <validation-provider #default="{ errors }"
                                           :rules="{ regex: /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/ }"
                                           name="Số điện thoại"
                      >
                        <b-form-group>
                          <label class="label-add-student" for="phoneMother">Số điện thoại</label>
                          <b-form-input id="phoneMother" v-model="modelStudent.motherNumber"
                                        :state="errors.length > 0 ? false : null" type="text" class="form-control"
                                        placeholder="Nhập số điện thoại mẹ"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col class="col-md-4 col-12">
                      <validation-provider #default="{ errors }" name="Email" rules="email">
                        <b-form-group>
                          <label class="label-add-student" for="motherEmail">Email</label>
                          <b-form-input id="motherEmail" v-model="modelStudent.motherEmail"
                                        :state="errors.length > 0 ? false : null" type="text" class="form-control"
                                        placeholder="Nhập email"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col class="col-md-4 col-12">
                      <validation-provider #default="{ errors }" name="Ngày sinh">
                        <b-form-group>
                          <label class="label-add-student" for="motherBirthday">Ngày sinh</label>
                          <b-form-group>
                            <flat-pickr id="motherBirthday" v-model="modelStudent.motherBirthday" class="form-control"
                                        :config="configDOBFlatpickr"
                                        placeholder="Chọn ngày sinh"
                                        :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col class="col-md-4 col-12">
                      <validation-provider #default="{ errors }" name="Công việc">
                        <b-form-group>
                          <label class="label-add-student" for="motherJob">Công việc</label>
                          <b-form-input id="motherJob" v-model="modelStudent.motherJob"
                                        :state="errors.length > 0 ? false : null" type="text" class="form-control"
                                        placeholder="Nhập công việc"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col class="col-md-4 col-12">
                      <validation-provider #default="{ errors }" name="Nơi công tác">
                        <b-form-group>
                          <label class="label-add-student" for="motherJobPosition">Nơi công tác</label>
                          <b-form-input id="motherJobPosition" v-model="modelStudent.motherJobPosition"
                                        :state="errors.length > 0 ? false : null" type="text" class="form-control"
                                        placeholder="Nhập nơi công tác"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="col-12">
                      <h5>- Người giám hộ:</h5>
                    </b-col>
                    <b-col class="col-md-4 col-12">
                      <validation-provider #default="{ errors }" name="Họ và tên người giám hộ">
                        <b-form-group>
                          <label class="label-add-student" for="nameGuardian">
                            Họ và tên
                          </label>
                          <b-form-input id="nameGuardian" v-model="modelStudent.guardianName"
                                        :state="errors.length > 0 ? false : null" type="text" class="form-control"
                                        placeholder="Nhập họ tên người giám hộ"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col class="col-md-4 col-12">
                      <validation-provider #default="{ errors }" name="Số điện thoại"
                                           :rules="{ regex: /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/ }"
                      >
                        <b-form-group class="form-group">
                          <label class="label-add-student" for="phoneGuardian">Số điện thoại</label>
                          <b-form-input id="phoneGuardian" v-model="modelStudent.guardianNumber"
                                        :state="errors.length > 0 ? false : null" type="text" class="form-control"
                                        placeholder="Nhập số điện thoại người giám hộ"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col class="col-md-4 col-12">
                      <validation-provider #default="{ errors }" name="Email" rules="email">
                        <b-form-group>
                          <label class="label-add-student" for="guardianEmail">Email</label>
                          <b-form-input id="guardianEmail" v-model="modelStudent.guardianEmail"
                                        :state="errors.length > 0 ? false : null" type="text" class="form-control"
                                        placeholder="Nhập email"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col class="col-md-4 col-12">
                      <validation-provider #default="{ errors }" name="Ngày sinh">
                        <b-form-group>
                          <label class="label-add-student" for="guardianBirthday">Ngày sinh</label>
                          <b-form-group>
                            <flat-pickr id="guardianBirthday" v-model="modelStudent.guardianBirthday" class="form-control"
                                        :config="configDOBFlatpickr"
                                        placeholder="Chọn ngày sinh"
                                        :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col class="col-md-4 col-12">
                      <validation-provider #default="{ errors }" name="Công việc">
                        <b-form-group>
                          <label class="label-add-student" for="guardianJob">Công việc</label>
                          <b-form-input id="guardianJob" v-model="modelStudent.guardianJob"
                                        :state="errors.length > 0 ? false : null" type="text" class="form-control"
                                        placeholder="Nhập công việc"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col class="col-md-4 col-12">
                      <validation-provider #default="{ errors }" name="Nơi công tác">
                        <b-form-group>
                          <label class="label-add-student" for="guardianJobPosition">Nơi công tác</label>
                          <b-form-input id="guardianJobPosition" v-model="modelStudent.guardianJobPosition"
                                        :state="errors.length > 0 ? false : null" type="text" class="form-control"
                                        placeholder="Nhập nơi công tác"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col v-if="modelStudent.levelId === 4" class="col-lg-3 col-sm-6 col-12">
                      <div class="form-group">
                        <h6 for="address">Quan hệ của người giám hộ với học
                          sinh</h6>
                        <input id="relation" v-model="modelStudent.relation" type="text" class="form-control"
                               placeholder="Nhập quan hệ của người giám hộ với học sinh"
                        >
                      </div>
                    </b-col>
                  </b-row>
                  <!--                <b-row>-->
                  <!--                  <b-col class="col-md-6 col-12">-->
                  <!--                    <div class="form-group">-->
                  <!--                      <h6 for="companyAddress">Công ty (Bố, Mẹ hoặc người-->
                  <!--                        giám hộ) - Địa-->
                  <!--                        chỉ</h6>-->
                  <!--                      <input id="companyAddress" v-model="modelStudent.company" type="text" class="form-control"-->
                  <!--                             placeholder="Nhập công ty (bố, mẹ hoặc người giám hộ), địa chỉ"-->
                  <!--                      >-->
                  <!--                    </div>-->
                  <!--                  </b-col>-->
                  <!--                  <b-col class="col-lg-3 col-sm-6 col-12">-->
                  <!--                    <div class="form-group">-->
                  <!--                      <h6 for="position">-->
                  <!--                        Chức vụ-->
                  <!--                      </h6>-->
                  <!--                      <input id="position" v-model="modelStudent.position" type="text" class="form-control"-->
                  <!--                             placeholder="Nhập chức vụ"-->
                  <!--                      >-->
                  <!--                    </div>-->
                  <!--                  </b-col>-->
                  <!--                </b-row>-->
                  <!--                <b-row>-->
                  <!--                  <b-col>-->
                  <!--                    <div class="form-group">-->
                  <!--                      <h6 class="mr-1">-->
                  <!--                        Chọn người liên hệ chính:-->
                  <!--                      </h6>-->
                  <!--                      <div class="demo-inline-spacing">-->
                  <!--                        <b-form-radio-group v-model="modelStudent.mainContact" :options="optionMainContact"-->
                  <!--                                            name="register-mainContact" class="mt-0" value-field="value"-->
                  <!--                                            text-field="text"-->
                  <!--                        />-->
                  <!--                      </div>-->
                  <!--                    </div>-->
                  <!--                  </b-col>-->
                  <!--                </b-row>-->
                  <b-row>
                    <b-col>
                      <div class="form-group">
                        <h6 class="">
                          Có anh, chị, em ruột hay không?
                        </h6>
                        <div class="demo-inline-spacing">
                          <b-form-radio-group v-model="sibling" :options="siblingOptions" name="sibling"
                                              value-field="value" text-field="label" class="mt-0"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row v-if="sibling === 1">
                    <b-col>
                      <div class="form-group">
                        <h6 class="" for="position">
                          Thông tin anh, chị em ruột
                        </h6>
                        <div>
                          <b-form ref="form" :style="{ height: trHeight }" class="repeater-form"
                                  @submit.prevent="repeatAgain"
                          >

                            <b-row v-for="(item, index) in modelStudent.familyOfStudentRegister" :id="item.id"
                                   :key="item.id"
                                   ref="row" class="repeat_row"
                            >
                              <b-col md="10">
                                <b-row>
                                  <b-col md="4">
                                    <validation-provider #default="{ errors }" name="Họ tên" rules="required">
                                      <label class="">Họ và tên <span class="text-danger">(*)</span></label>
                                      <b-form-group>
                                        <b-form-input id="item-name" v-model="item.fullName" type="text"
                                                      :state="errors.length > 0 ? false : null"
                                                      placeholder="Nhập họ và tên"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                      </b-form-group>
                                    </validation-provider>
                                  </b-col>
                                  <b-col md="4">
                                    <validation-provider #default="{ errors }" name="Ngày sinh" rules="required">
                                      <label class="">Ngày sinh <span class="text-danger">(*)</span></label>
                                      <b-form-group>
                                        <flat-pickr v-model="item.birthDay" class="form-control"
                                                    :config="configDOBFlatpickr"
                                                    placeholder="Chọn ngày sinh"
                                                    :state="errors.length > 0 ? false : null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                      </b-form-group>
                                    </validation-provider>
                                  </b-col>

                                  <b-col lg="4" md="4">
                                    <validation-provider #default="{ errors }" name="Giới tính" rules="required">
                                      <label class="">Giới tính <span class="text-danger">(*)</span></label>
                                      <b-form-group :state="errors.length > 0 ? false : null">
                                        <v-select v-model="item.gender"
                                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                  label="text" placeholder="Chọn giới tính" :options="sexOptions"
                                                  :reduce="(option) => option.value"
                                        >
                                          <span slot="no-options">Không có dữ liệu.</span>
                                        </v-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                      </b-form-group>
                                    </validation-provider>
                                  </b-col>
                                  <b-col lg="6" md="6">
                                    <validation-provider #default="{ errors }" rules="">
                                      <label class="">Đang học tại <b>Đa Trí Tuệ</b> không?</label>
                                      <b-form-group :state="errors.length > 0 ? false : null">
                                        <v-select v-model="item.isMisStudent"
                                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                  label="label" :options="isMisStudentOptions"
                                                  :reduce="(option) => option.value"
                                        >
                                          <span slot="no-options">Không có dữ liệu.</span>
                                        </v-select>
                                      </b-form-group>
                                    </validation-provider>
                                  </b-col>
                                  <b-col md="6">
                                    <validation-provider v-if="item.isMisStudent === 0" #default="{ errors }"
                                                         name="Tên trường" rules="required"
                                    >
                                      <label class="">Tên trường <span class="text-danger">(*)</span></label>
                                      <b-form-group label-for="item-schoolName">
                                        <b-form-input id="item-schoolName" v-model="item.schoolName" type="text"
                                                      :state="errors.length > 0 ? false : null"
                                                      placeholder="Nhập tên trường"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                      </b-form-group>
                                    </validation-provider>
                                  </b-col>
                                </b-row>
                              </b-col>
                              <b-col class="mt-3">
                                <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-success"
                                          class="btn-icon rounded-circle mt-0 mt-md-2" @click="repeatAgain()"
                                >
                                  <feather-icon icon="PlusIcon" />
                                </b-button>
                                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="flat-danger"
                                          class="btn-icon rounded-circle mt-0 mt-md-2" @click="removeItem(index)"
                                >
                                  <feather-icon icon="XIcon" />
                                </b-button>
                              </b-col>
                            </b-row>
                          </b-form>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div id="study-info" class="header-info" style="border-bottom: 1px solid #d8d6de; margin-bottom: 2rem">
                  <h4>THÔNG TIN HỌC TẬP</h4>
                  <b-row>
                    <b-col md="4">
                      <div class="form-group">
                        <h6 for="school-attended">
                          Trường đã/đang học
                        </h6>
                        <input id="school-attended" v-model="modelStudent.schoolName" type="text" class="form-control"
                               placeholder="Nhập trường đã/đang học"
                        >
                      </div>
                    </b-col>
                    <b-col md="4">
                      <b-form-group>
                        <h6>Tỉnh/Thành phố</h6>
                        <v-select v-model="modelStudent.cityAttended"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  label="label" placeholder="Chọn thành phố" :options="cityOptions"
                                  :reduce="(option) => option.value"
                        >
                          <span slot="no-options">Không có dữ liệu.</span>
                        </v-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <h6>Đăng ký lịch kiểm tra</h6>
                      <v-select v-model="modelStudent.examinationDate" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="label" placeholder="Chọn ngày" :options="nextSaturdaysOptions"
                                :reduce="(option) => option.value"
                      >
                      </v-select>
                    </b-col>
                  </b-row>
                  <b-row v-if="modelStudent.currentClass">
                    <b-col md="12">
                      <h6>Kết quả học tập năm trước</h6>
                    </b-col>
                    <b-col md="12">
                      <b-row>
                        <b-col md="3">
                          <b-form-group>
                            <h6 for="class-studied">
                              Lớp đã/đang học
                            </h6>
                            <input id="class-studied" v-model="modelStudent.currentClass" type="text"
                                   class="form-control"
                                   placeholder="Nhập lớp đã/đang học" disabled
                            >
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group>
                            <h6 for="conduct">
                              Hạnh kiểm
                            </h6>
                            <input id="conduct" v-model="modelStudent.conduct" type="text" class="form-control"
                                   placeholder="Nhập hạnh kiểm"
                            >
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="12">
                      <b-row>
                        <b-col md="2">
                          <h6 class="font-italic mt-3">
                            Toán:
                          </h6>
                        </b-col>
                        <b-col md="3">
                          <b-form-group>
                            <h6 for="mathSemester1Score">
                              Học kỳ 1
                            </h6>
                            <input id="mathSemester1Score" v-model="modelStudent.mathSemester1Score" type="number"
                                   class="form-control"
                                   placeholder="Nhập điểm toán học kỳ 1"
                            >
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group>
                            <h6 for="mathSemester2Score">
                              Học kỳ 2
                            </h6>
                            <input id="mathSemester2Score" v-model="modelStudent.mathSemester2Score" type="number"
                                   class="form-control"
                                   placeholder="Nhập điểm toán học kỳ 2"
                            >
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="2">
                          <h6 v-if="modelStudent.levelId === 1" class="font-italic mt-3">
                            Tiếng Việt:
                          </h6>
                          <h6 v-else class="font-italic mt-3">
                            Ngữ Văn:
                          </h6>
                        </b-col>
                        <b-col md="3">
                          <b-form-group>
                            <h6 for="literatureSemester1Score">
                              Học kỳ 1
                            </h6>
                            <input id="literatureSemester1Score" v-model="modelStudent.literatureSemester1Score"
                                   type="number" class="form-control"
                                   placeholder="Nhập điểm văn học kỳ 1"
                            >
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group>
                            <h6 for="literatureSemester2Score">
                              Học kỳ 2
                            </h6>
                            <input id="literatureSemester2Score" v-model="modelStudent.literatureSemester2Score"
                                   type="number" class="form-control"
                                   placeholder="Nhập điểm văn học kỳ 2"
                            >
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="2">
                          <h6 class="font-italic mt-3">
                            Tiếng Anh:
                          </h6>
                        </b-col>
                        <b-col md="3">
                          <b-form-group>
                            <h6 for="englishSemester1Score">
                              Học kỳ 1
                            </h6>
                            <input id="englishSemester1Score" v-model="modelStudent.englishSemester1Score" type="number"
                                   class="form-control"
                                   placeholder="Nhập điểm tiếng Anh học kỳ 1"
                            >
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group>
                            <h6 for="englishSemester2Score">
                              Học kỳ 2
                            </h6>
                            <input id="englishSemester2Score" v-model="modelStudent.englishSemester2Score" type="number"
                                   class="form-control"
                                   placeholder="Nhập điểm tiếng Anh học kỳ 2"
                            >
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <h6>Các môn học yêu thích</h6>
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <h6 for="favourite-subject">
                          Môn học
                        </h6>
                        <input id="favourite-subject" v-model="modelStudent.subjects" type="text" class="form-control"
                               placeholder="Nhập môn học yêu thích"
                        >
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <h6 for="best-subject">
                          Môn học tốt nhất
                        </h6>
                        <input id="best-subject" v-model="modelStudent.bestSubjects" type="text" class="form-control"
                               placeholder="Nhập môn học tốt nhất"
                        >
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <h6 for="achievements">
                          Thành tích đạt được
                        </h6>
                        <b-form-textarea id="achievements" v-model="modelStudent.achievements"
                                         placeholder="Nhập thành tích đạt được" rows="3"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div v-if="modelStudent.levelId !== 4">
                    <b-row v-for="(item, index) in modelStudent.entryScoreList"
                          :key="index">
                      <b-col cols="12">
                        <h6>Điểm kiểm tra đầu vào lần {{ index + 1 }}</h6>
                      </b-col>
                      <b-col cols="12">
                        <b-row>
                          <b-col lg="3" sm="6">
                            <div class="form-group">
                              <label>Ngày kiểm tra</label>
                              <flat-pickr v-model="item.entryDate"
                                          class="form-control" :config="configDOBFlatpickr"
                                          placeholder="Chọn ngày kiểm tra"
                              />
                            </div>
                          </b-col>
                          <b-col lg="9" sm="6">
                            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-success"
                                      class="btn-icon rounded-circle mt-0 mt-md-2" @click="addEntryScore"
                                      v-b-tooltip.hover.top="'Thêm bản ghi'"
                                      v-if="index + 1 === modelStudent.entryScoreList.length">
                              <feather-icon icon="PlusIcon"/>
                            </b-button>
                            <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="flat-danger"
                                      class="btn-icon rounded-circle mt-0 mt-md-2"
                                      @click="removeEntryScore(index)"
                                      v-if="modelStudent.entryScoreList.length > 1"
                                      v-b-tooltip.hover.top="'Xóa bản ghi'">
                              <feather-icon icon="XIcon"/>
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col lg="3" sm="6">
                        <div class="form-group">
                          <label>Toán</label>
                          <input id="entryMathScore" v-model="item.entryMathScore" type="text"
                                class="form-control"
                                placeholder="Nhập điểm toán"
                          >
                        </div>
                      </b-col>
                      <b-col lg="3" sm="6">
                        <div class="form-group">
                          <label>Văn/Tiếng việt</label>
                          <input id="entryLiteratureScore" v-model="item.entryLiteratureScore" type="text"
                                class="form-control"
                                placeholder="Nhập điểm toán"
                          >
                        </div>
                      </b-col>
                      <b-col lg="3" sm="6">
                        <div class="form-group">
                          <label>Tiếng Anh</label>
                          <input id="entryEnglishScore" v-model="item.entryEnglishScore" type="text"
                                class="form-control"
                                placeholder="Nhập điểm toán"
                          >
                        </div>
                      </b-col>
                      <b-col lg="3" sm="6">
                        <div class="form-group">
                          <label>Phỏng vấn/Tư duy</label>
                          <input id="entryInterviewScore" v-model="item.entryInterviewScore" type="text"
                                class="form-control"
                                placeholder="Nhập điểm toán"
                          >
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div id="other-info" class="header-info">
                  <h4>THÔNG TIN KHÁC</h4>
                  <b-row>
                    <b-col md="6">
                      <b-form-group>
                        <h6>Năng khiếu: Thể thao - Âm nhạc - Thể thao - Các môn
                          khác</h6>
                        <b-form-textarea id="textarea-default" v-model="modelStudent.gifted"
                                         placeholder="Nhập thông tin năng khiếu" rows="3"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <h6>Sở thích</h6>
                        <b-form-textarea id="hobbies" v-model="modelStudent.hobbies"
                                         placeholder="Nhập sở thích" rows="3"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <h6>Món ăn yêu thích</h6>
                        <b-form-textarea id="favoriteDishes" v-model="modelStudent.favoriteDishes"
                                         placeholder="Nhập món ăn yêu thích" rows="3"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <h6>Lưu ý về sức khỏe</h6>
                        <b-form-textarea id="healthIssues" v-model="modelStudent.healthIssues"
                                         placeholder="Nhập lưu ý" rows="3"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="5">
                      <div class="form-group">
                        <h6 class="label-add-student">Đăng ký xe đưa đón</h6>
                        <div class="demo-inline-spacing">
                          <b-form-radio-group v-model="modelStudent.shuttle" :options="optionShuttle"
                                              name="register-shuttle"
                                              class="mt-0" value-field="value" text-field="text"
                          />
                        </div>
                      </div>
                    </b-col>
                    <b-col md="3">
                      <div class="form-group">
                        <h6 class="label-add-student">Đăng ký ở nội trú</h6>
                        <div class="demo-inline-spacing">
                          <b-form-radio-group v-model="modelStudent.boarding" :options="optionBoarding"
                                              name="register-boarding" class="mt-0" value-field="value" text-field="text"
                          />
                        </div>
                      </div>
                    </b-col>
                    <b-col md="4" v-if="modelStudent.boarding === 0">
                      <label>Đăng ký nội trú</label>
                      <v-select v-model="modelStudent.boardingType" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="label" placeholder="Đăng ký nội trú" :options="boardingTypeOptions"
                                :reduce="(option) => option.value"
                      >
                        <span slot="no-options">Không có dữ liệu.</span>
                      </v-select>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col v-if="modelStudent.shuttle != 2" md="6">
                      <b-form-group>
                        <label class="label-add-student">Địa điểm đón</label>
                        <input id="pickup-location" v-model="modelStudent.pickupLocation" type="text" class="form-control"
                               placeholder="Nhập địa điểm"
                        >
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <h6>Bạn biết chúng tôi qua đâu ?</h6>
                      <v-select v-model="modelStudent.communication" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="label" placeholder="Chọn nền tảng" :options="communicationOptions"
                                :reduce="(option) => option.value"
                      >
                        <span slot="no-options">Không có dữ liệu.</span>
                      </v-select>
                    </b-col>
                    <b-col md="6" v-if="modelStudent.communication == 5">
                      <label>Người giới thiệu</label>
                      <!-- <input v-model="modelStudent.communicationDescription" type="text" class="form-control"></b-form-textarea> -->
                      <b-form-textarea v-model="modelStudent.communicationDescription" class="form-control" placeholder="Nhập thông tin người giới thiệu" rows="1"/>
                    </b-col>
                    <b-col md="6" v-if="modelStudent.communication == 6">
                      <label>Mô tả khác</label>
                      <b-form-textarea v-model="modelStudent.communicationDescription" class="form-control" placeholder="Nhập thông tin khác" rows="1"/>
                      <!-- <input v-model="modelStudent.communicationDescription" type="text" class="form-control"> -->
                    </b-col>
                    <!-- <b-col md="6" v-if="modelStudent.communication === 4">
                      <div class="form-group">
                        <h6>Người giới thiệu</h6>
                        <b-form-textarea id="presenter" v-model="modelStudent.presenter"
                                         placeholder="Nhập thông tin người giới thiệu" rows="1"
                        />
                      </div>
                    </b-col>
                    <b-col md="6" v-if="modelStudent.communication === 5">
                      <div class="form-group">
                        <h6>Khác</h6>
                        <b-form-textarea id="recommender" v-model="modelStudent.presenter"
                                         placeholder="Nhập thông tin khác" rows="1"
                        />
                      </div>
                    </b-col> -->
                  </b-row>
                  <b-row class="mt-2">
<!--                    <b-col md="3">-->
<!--                      <b-form-group>-->
<!--                        <h6>Nhân viên tư vấn</h6>-->
<!--                        <input id="counselorEmployeeName" v-model="modelStudent.counselorEmployeeName" type="text" class="form-control"-->
<!--                               placeholder="Nhập nhân viên tư vấn"-->
<!--                        >-->
<!--                      </b-form-group>-->
<!--                    </b-col>-->
                    <b-col md="12">
                      <div class="form-group">
                        <h6>Ghi chú</h6>
                        <b-form-textarea id="textNote" v-model="modelStudent.note"
                                         placeholder="Nhập ghi chú" rows="3"
                        />
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="6">
                      <div class="push-file">
                        <h6 for="wildcard">File đính
                          kèm</h6>
                        <p>Ghi chú: File bổ sung thông tin (VD: bảng điểm, học bạ, ...)</p>
                        <b-form-file id="wildcard" multiple browse-text="Chọn file" accept=""
                                     placeholder="Vui lòng chọn file" @input="importFileAttach"
                        />
                      </div>
                    </b-col>
                    <b-col v-if="listFileShowTable.length > 0" xl="6" sm="12" cols="12">
                      <div class="data-file-import mt-2">
                        <b-table :items="listFileShowTable" :fields="fieldFile">
                          <template #cell(index)="data">
                            <span>{{ data.index + 1 }}</span>
                          </template>
                          <template v-if="listFileShowTable.length > 0" #cell(hanhdong)="data">
                            <b-button v-if="data.item.id" v-b-tooltip.hover.top="'Xóa'" variant="flat-danger"
                                      class="btn-icon" @click="deleteFile(data.item)"
                            >
                              <feather-icon icon="TrashIcon" />
                            </b-button>
                            <b-button v-if="data.item.id === null" v-b-tooltip.hover.top="'Xóa'" variant="flat-danger"
                                      class="btn-icon" @click="deletFileNoCallApi(data.item)"
                            >
                              <feather-icon icon="TrashIcon" />
                            </b-button>
                            <b-button v-if="data.item.id" v-b-tooltip.hover.top="'Tải file'" variant="flat-success"
                                      class="btn-icon" @click="downFileCallApi(data.item)"
                            >
                              <feather-icon icon="DownloadIcon" />
                            </b-button>
                          </template>
                        </b-table>
                      </div>
                    </b-col>
                  </b-row>
                  <!--                  <b-row v-if="listFileShowTable.length > 0">-->
                  <!--                    -->
                  <!--                  </b-row>-->
                  <!--                <b-row v-if="tuitions">-->
                  <!--                  <b-col class="col-md-6 mt-2">-->
                  <!--                    <div class="form-group">-->
                  <!--                      <h6>Hình thức đóng học phí</h6>-->
                  <!--                      <div class="demo-inline-spacing">-->
                  <!--                        <b-form-radio-group v-model="modelStudent.tuitionType" :options="optionTuitionType"-->
                  <!--                                            name="tuitionType" class="mt-0" value-field="value" text-field="text"-->
                  <!--                                            @change="selectedEvent()"-->
                  <!--                        />-->
                  <!--                      </div>-->
                  <!--                    </div>-->
                  <!--                  </b-col>-->
                  <!--                </b-row>-->
                  <!--                <b-row v-if="tuitions">-->
                  <!--                  <b-col class="col-md-6 mt-2">-->
                  <!--                    <div class="form-group">-->
                  <!--                      <h6 for="address">-->
                  <!--                        Học phí-->
                  <!--                      </h6>-->
                  <!--                      <cleave id="number" v-model="modelStudent.tuition" class="form-control" :raw="true"-->
                  <!--                              :disabled="true"-->
                  <!--                              :options="optionsNumber.number"-->
                  <!--                      />-->
                  <!--                    </div>-->
                  <!--                  </b-col>-->
                  <!--                </b-row>-->
                </div>
                <div class="float-right mt-2">
                  <b-button variant="success" @click="addStudent">
                    Đăng ký
                  </b-button>
                  <b-button variant="outline-danger" class="ml-1" @click="$router.go(-1)">
                    Quay lại
                  </b-button>
                </div>
              </validation-observer>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min, max } from '@validations'
import {
  BButton,
  BCard,
  BCol,
  BForm,
  // BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormTextarea,
  BRow,
  BFormFile,
  BTable,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import province from '@/assets/utils/province'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import * as moment from 'moment'
import 'moment/locale/vi'
// eslint-disable-next-line import/extensions
import UploadImage from '@/views/registration-form/UploadImage'

export default {
  name: 'AddStudent',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    flatPickr,
    BForm,
    BFormInput,
    BButton,
    BFormTextarea,
    // BFormCheckboxGroup,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BTable,
    // Cleave,
    UploadImage,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [heightTransition],

  data() {
    return {
      tuitions: null,
      semesterDiscount: null,
      yearDiscount: null,
      selectedEvents: [],
      optionsNumber: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      statuss: null,
      checkLevel: false,
      required,
      min,
      max,
      filePush: '',
      isClickFile: false,
      isUpdate: false,
      nameStutdent: '',
      listPustFileEndcoedBase64: [],
      fileStudent: null,
      idStudent: null,
      modelStudent: {
        image: {
          file: null,
          fileName: null,
        },
        id: null,
        contactTime: null,
        relation: null,
        height: null,
        weight: null,
        tuition: null,
        achievements: '',
        address: '',
        bestSubjects: '',
        birthDay: null,
        scholarship: 0,
        boarding: 1,
        boardingType:1,
        classEntity: {
          classType: null,
          name: '',
        },
        promotionsSchools: [],
        studentRegisterProfiles: [],
        currentClass: '',
        district: '',
        email: '',
        familyOfStudentRegister: [
          {
            id: 1,
            schoolName: '',
            birthDay: '',
            employeeCode: '',
            fullName: '',
            gender: null,
            isMisStudent: 1,
          },
        ],
        studentContactHistoryDTOList: [
          {
            contactDate: '',
            description: '',
          },
        ],
        company: '',
        position: '',
        firstName: '',
        fullName: '',
        gender: '',
        gifted: '',
        houseNumber: '',
        lastName: '',
        levelId: null,
        livingWith: [],
        phoneNumber: '',
        presenter: '',
        province: '',
        schoolName: '',
        shuttle: 2,
        startDate: new Date(),
        status: null,
        studentCode: '',
        studyAboard: 1,
        subDistrict: '',
        subjects: '',
        username: '',
        country: '',
        fatherName: '',
        fatherNumber: '',
        fatherEmail: '',
        fatherBirthday: null,
        fatherJob: '',
        fatherJobPosition: '',
        motherName: '',
        motherNumber: '',
        motherEmail: '',
        motherBirthday: null,
        motherJob: '',
        motherJobPosition: '',
        guardianName: '',
        guardianNumber: '',
        guardianEmail: '',
        guardianBirthday: null,
        guardianJob: '',
        guardianJobPosition: '',
        cityAttended: '',
        mathSemester1Score: null,
        mathSemester2Score: null,
        literatureSemester1Score: null,
        literatureSemester2Score: null,
        englishSemester1Score: null,
        englishSemester2Score: null,
        ethnic: null,
        conduct: null,
        grade: null,
        communication: null,
        hobbies: '',
        favoriteDishes: '', //Món ăn yêu thích
        healthIssues: '', //Vấn đề về sức khỏe cần lưu ý
        counselorEmployeeName: '', //Nhân viên tư vấn
        examinationDate: null, //Đăng ký lịch test vào t7 các ngày trong tuần
        studentEducationNumber: null,
        studentIdentificationNumber: null,
        admissionStatus:0, //phụ huynh đăng ký ở form thì mặc định là chưa thi
        communicationDescription:null,
        entryScoreList: [
          {
            id: null,
            entryDate: new Date(),
            entryEnglishScore: 0,
            entryInterviewScore: '', //Điểm tư duy/phỏng vấn
            entryLiteratureScore: 0,
            entryMathScore: 0,
          }
        ],
      },
      configFlatpickr: {
        wrap: true,
        altFormat: 'd/m/Y',
        altInput: true,
        locale: Vietnamese,
        allowInput: true,
      },
      configDOBFlatpickr: {
        wrap: true,
        altFormat: 'd/m/Y',
        altInput: true,
        locale: Vietnamese,
        allowInput: true,
        maxDate: new Date(),
      },
      configDateTimeFlatpickr: {
        wrap: true,
        enableTime: true,
        altFormat: 'd/m/Y H:i',
        altInput: true,
        locale: Vietnamese,
        allowInput: true,
        minDate: new Date(),
        time_24hr: true,
      },
      configContatcDate: {
        wrap: true,
        altFormat: 'd/m/Y',
        altInput: true,
        locale: Vietnamese,
        allowInput: true,
        maxDate: new Date(),
      },
      dateDob: null,
      dateItemDob: null,
      startDate: null,
      cityOptions: [{
        value: '',
        label: '',
      }],
      listFileImport: [],
      listFileShowTable: [],
      districtOptions: [],
      subDistrictOptions: [],
      items: [{
        id: 1,
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
      }],
      optionTuitionType: [
        { id: 1, text: 'Theo tháng', value: 0 },
        { id: 2, text: 'Theo học kỳ', value: 1 },
        { id: 3, text: 'Theo năm', value: 2 },
      ],
      optionsLevelSchool: [],
      sexOptions: [
        { id: 1, text: 'Nam', value: 1 },
        { id: 2, text: 'Nữ', value: 2 },
      ],
      optionMainContact: [
        { id: 1, text: 'Bố', value: 1 },
        { id: 2, text: 'Mẹ', value: 0 },
        { id: 3, text: 'Người giám hộ', value: 4 },
      ],
      statusOptions: [
        { id: 1, text: 'Đã liên hệ', value: '0' },
        { id: 2, text: 'Đã ghi danh', value: '1' },
        { id: 3, text: 'Đã kiểm tra đầu vào', value: '2' },
        { id: 4, text: 'Chưa nhập học', value: '3' },
        { id: 5, text: 'Đã nhập học', value: '4' },
        { id: 6, text: 'Tạo trạng thái mới', value: '5' },
      ],
      admissionStatusOptions: [
        { text: 'Đã trúng tuyển', value: 1 },
        { text: 'Chưa trúng tuyển', value: 2 },
      ],
      fieldFile: [
        {
          key: 'index', label: 'stt',
        },
        {
          key: 'fileName', label: 'Tên file',
        },
        {
          key: 'hanhdong', label: 'Hành động',
        },
      ],
      selected: [],
      optionsLevel1: [],
      optionsLevel2: [],
      optionsLevel3: [],
      optionsLevel4: [],
      optionsLevel5: [],
      optionsLevel6: [],
      optionsLevel7: [],
      optionsLevel8: [],
      optionsLevel9: [],
      optionsLevel10: [],
      optionsLevel11: [],
      optionsLevel12: [],
      optionLivingWith: [
        { text: 'Bố', value: { relation: 1 } },
        { text: 'Mẹ', value: { relation: 0 } },
        { text: 'Bố dượng', value: { relation: 3 } },
        { text: 'Mẹ kế', value: { relation: 2 } },
        { text: 'Người giám hộ', value: { relation: 4 } },
      ],

      optionShuttle: [
        { text: 'Tại bến', value: 0 },
        { text: 'Tại nhà', value: 1 },
        { text: 'Không đăng ký', value: 2 },
      ],
      optionBoarding: [
        { text: 'Có', value: 0 },
        { text: 'Không', value: 1 },
      ],
      boardingTypeOptions: [
        {label: 'Mức 1 (3 người)', value: 1},
        {label: 'Mức 2 (8 người)', value: 2},
        {label: 'Mức 3 (10 người)', value: 3}
      ],
      optionStudyAbroad: [
        { text: 'Có', value: 0 },
        { text: 'Không', value: 1 },
      ],
      optionScholarship: [
        { text: 'Đạt', value: 0 },
        { text: 'Không đạt', value: 1 },
      ],
      nextTodoId: 2,
      nextTodoIdHis: 2,
      nameFile: '',
      // menuHidden: this.$store.state.appConfig.layout.menu.hidden,
      communicationOptions: [
        {label: 'Không', value: null},
        {label: 'Facebook', value: 1},
        {label: 'Google', value: 2},
        {label: 'Mail', value: 3},
        {label: 'Television', value: 4},
        {label: 'Người giới thiệu', value: 5},
        {label: 'Khác', value: 6}
      ],
      interviewStatusOptions: [
        { label: 'Không đạt', value: 0 },
        { label: 'Đạt', value: 1 },
      ],
      sibling: 0,
      siblingOptions: [
        { label: 'Có', value: 1 },
        { label: 'Không', value: 0 },
      ],
      isMisStudentOptions: [
        { label: 'Có', value: 1 },
        { label: 'Không', value: 0 },
      ],
      // level1Options: [
      //   { label: 'Lớp 1', value: 1 },
      //   { label: 'Lớp 2', value: 2 },
      //   { label: 'Lớp 3', value: 3 },
      //   { label: 'Lớp 4', value: 4 },
      //   { label: 'Lớp 5', value: 5 },
      // ],
      // level2Options: [
      //   { label: 'Lớp 6', value: 6 },
      //   { label: 'Lớp 7', value: 7 },
      //   { label: 'Lớp 8', value: 8 },
      //   { label: 'Lớp 9', value: 9 },
      // ],
      // level3Options: [
      //   { label: 'Lớp 10', value: 10 },
      //   { label: 'Lớp 11', value: 11 },
      //   { label: 'Lớp 12', value: 12 },
      // ],
      profileFile: null,
      images: {
        file: null,
        fileName: null,
      },
      nextSaturdaysOptions: [],
    }
  },
  beforeCreate() {
    const userData = localStorage.getItem('access_token')
    if (userData && window.location.pathname === '/dang-ky') {
      this.$router.push('/home')
    }
  },
  created() {
    this.cityOptions = province.getProvince()
    this.getListSchool()
    this.nextSaturdaysOptions = this.getNextSaturdays(10)
  },

  methods: {
    getListSchool() {
      this.$crm.get('guest/level-school/all').then(res => {
        this.optionsLevelSchool = res.data
      })
    },
    getListClass(id) {
      if (id) {
        this.modelStudent.tuition = null
        this.modelStudent.tuitionType = null
        this.modelStudent.currentClass = null
        this.modelStudent.mathSemester1Score = null
        this.modelStudent.mathSemester2Score = null
        this.modelStudent.literatureSemester1Score = null
        this.modelStudent.literatureSemester2Score = null
        this.modelStudent.englishSemester1Score = null
        this.modelStudent.englishSemester2Score = null
        this.modelStudent.conduct = null
        this.selectedEvents = []
        this.tuitions = null
        this.$crm.get(`guest/find-by-level-school-id/${id}`).then(res => {
          if (!this.isUpdate) {
            this.modelStudent.classEntity = {
              classType: null,
              name: '',
              id: null,
            }
          }
          this.optionsLevel1 = []
          this.optionsLevel2 = []
          this.optionsLevel3 = []
          this.optionsLevel4 = []
          this.optionsLevel5 = []
          this.optionsLevel6 = []
          this.optionsLevel7 = []
          this.optionsLevel8 = []
          this.optionsLevel9 = []
          this.optionsLevel10 = []
          this.optionsLevel11 = []
          this.optionsLevel12 = []
          if (res.data.length > 0) {
            res.data.forEach(item => {
              if (item.classType === 0 && item.name === 'Lớp 1') {
                this.optionsLevel1.push({
                  text: 'STEAM',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 0,
                  },
                })
              }
              if (item.classType === 1 && item.name === 'Lớp 1') {
                this.optionsLevel1.push({
                  text: 'CLC',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 1,
                  },
                })
              }
              if (item.classType === 2 && item.name === 'Lớp 1') {
                this.optionsLevel1.push({
                  text: 'NKNN',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 2,
                  },
                })
              }
              if (item.classType === 0 && item.name === 'Lớp 2') {
                this.optionsLevel2.push({
                  text: 'STEAM',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 0,
                  },
                })
              }
              if (item.classType === 1 && item.name === 'Lớp 2') {
                this.optionsLevel2.push({
                  text: 'CLC',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 1,
                  },
                })
              }
              if (item.classType === 2 && item.name === 'Lớp 2') {
                this.optionsLevel2.push({
                  text: 'NKNN',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 2,
                  },
                })
              }
              if (item.classType === 0 && item.name === 'Lớp 3') {
                this.optionsLevel3.push({
                  text: 'STEAM',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 0,
                  },
                })
              }
              if (item.classType === 1 && item.name === 'Lớp 3') {
                this.optionsLevel3.push({
                  text: 'CLC',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 1,
                  },
                })
              }
              if (item.classType === 2 && item.name === 'Lớp 3') {
                this.optionsLevel3.push({
                  text: 'NKNN',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 2,
                  },
                })
              }
              if (item.classType === 0 && item.name === 'Lớp 4') {
                this.optionsLevel4.push({
                  text: 'STEAM',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 0,
                  },
                })
              }
              if (item.classType === 1 && item.name === 'Lớp 4') {
                this.optionsLevel4.push({
                  text: 'CLC',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 1,
                  },
                })
              }
              if (item.classType === 2 && item.name === 'Lớp 4') {
                this.optionsLevel4.push({
                  text: 'NKNN',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 2,
                  },
                })
              }
              if (item.classType === 0 && item.name === 'Lớp 5') {
                this.optionsLevel5.push({
                  text: 'STEAM',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 0,
                  },
                })
              }
              if (item.classType === 1 && item.name === 'Lớp 5') {
                this.optionsLevel5.push({
                  text: 'CLC',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 1,
                  },
                })
              }
              if (item.classType === 2 && item.name === 'Lớp 5') {
                this.optionsLevel5.push({
                  text: 'NKNN',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 2,
                  },
                })
              }
              if (item.classType === 0 && item.name === 'Lớp 6') {
                this.optionsLevel6.push({
                  text: 'STEAM',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 0,
                  },
                })
              }
              if (item.classType === 1 && item.name === 'Lớp 6') {
                this.optionsLevel6.push({
                  text: 'CLC',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 1,
                  },
                })
              }
              if (item.classType === 2 && item.name === 'Lớp 6') {
                this.optionsLevel6.push({
                  text: 'NKNN',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 2,
                  },
                })
              }
              if (item.classType === 0 && item.name === 'Lớp 7') {
                this.optionsLevel7.push({
                  text: 'STEAM',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 0,
                  },
                })
              }
              if (item.classType === 1 && item.name === 'Lớp 7') {
                this.optionsLevel7.push({
                  text: 'CLC',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 1,
                  },
                })
              }
              if (item.classType === 2 && item.name === 'Lớp 7') {
                this.optionsLevel7.push({
                  text: 'NKNN',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 2,
                  },
                })
              }
              if (item.classType === 0 && item.name === 'Lớp 8') {
                this.optionsLevel8.push({
                  text: 'STEAM',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 0,
                  },
                })
              }
              if (item.classType === 1 && item.name === 'Lớp 8') {
                this.optionsLevel8.push({
                  text: 'CLC',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 1,
                  },
                })
              }
              if (item.classType === 2 && item.name === 'Lớp 8') {
                this.optionsLevel8.push({
                  text: 'NKNN',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 2,
                  },
                })
              }
              if (item.classType === 0 && item.name === 'Lớp 9') {
                this.optionsLevel9.push({
                  text: 'STEAM',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 0,
                  },
                })
              }
              if (item.classType === 1 && item.name === 'Lớp 9') {
                this.optionsLevel9.push({
                  text: 'CLC',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 1,
                  },
                })
              }
              if (item.classType === 2 && item.name === 'Lớp 9') {
                this.optionsLevel9.push({
                  text: 'NKNN',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 2,
                  },
                })
              }
              if (item.classType === 4 && item.name === 'Lớp 10') {
                this.optionsLevel10.push({
                  text: 'STEAM A',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 4,
                  },
                })
              }
              if (item.classType === 5 && item.name === 'Lớp 10') {
                this.optionsLevel10.push({
                  text: 'STEAM D',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 5,
                  },
                })
              }
              if (item.classType === 6 && item.name === 'Lớp 10') {
                this.optionsLevel10.push({
                  text: 'CLC A',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 6,
                  },
                })
              }
              if (item.classType === 7 && item.name === 'Lớp 10') {
                this.optionsLevel10.push({
                  text: 'CLC D',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 7,
                  },
                })
              }
              if (item.classType === 2 && item.name === 'Lớp 10') {
                this.optionsLevel10.push({
                  text: 'NKNN',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 2,
                  },
                })
              }
              if (item.classType === 4 && item.name === 'Lớp 11') {
                this.optionsLevel11.push({
                  text: 'STEAM A',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 4,
                  },
                })
              }
              if (item.classType === 5 && item.name === 'Lớp 11') {
                this.optionsLevel11.push({
                  text: 'STEAM D',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 5,
                  },
                })
              }
              if (item.classType === 6 && item.name === 'Lớp 11') {
                this.optionsLevel11.push({
                  text: 'CLC A',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 6,
                  },
                })
              }
              if (item.classType === 7 && item.name === 'Lớp 11') {
                this.optionsLevel11.push({
                  text: 'CLC D',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 7,
                  },
                })
              }
              if (item.classType === 2 && item.name === 'Lớp 11') {
                this.optionsLevel11.push({
                  text: 'NKNN',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 2,
                  },
                })
              }
              if (item.classType === 4 && item.name === 'Lớp 12') {
                this.optionsLevel12.push({
                  text: 'STEAM A',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 4,
                  },
                })
              }
              if (item.classType === 5 && item.name === 'Lớp 12') {
                this.optionsLevel12.push({
                  text: 'STEAM D',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 5,
                  },
                })
              }
              if (item.classType === 6 && item.name === 'Lớp 12') {
                this.optionsLevel12.push({
                  text: 'CLC A',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 6,
                  },
                })
              }
              if (item.classType === 7 && item.name === 'Lớp 12') {
                this.optionsLevel12.push({
                  text: 'CLC D',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 7,
                  },
                })
              }
              if (item.classType === 2 && item.name === 'Lớp 12') {
                this.optionsLevel12.push({
                  text: 'NKNN',
                  value: {
                    id: item.id,
                    name: item.name,
                    classType: 2,
                  },
                })
              }
            })
            this.optionsLevel10.push(this.optionsLevel10.shift())
            this.optionsLevel11.push(this.optionsLevel11.shift())
            this.optionsLevel12.push(this.optionsLevel12.shift())
          } else {
            this.tuitions = null
            this.modelStudent.tuition = null
            this.modelStudent.tuitionType = null
          }
        })
      }
    },
    repeatAgain() {
      this.modelStudent.familyOfStudentRegister.push({
        isMisStudent: 1,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      if (this.modelStudent.familyOfStudentRegister.length > 1) {
        this.modelStudent.familyOfStudentRegister.splice(index, 1)
        this.trTrimHeight(this.$refs.row[0].offsetHeight)
      }
    },
    repeatAgainContactHistory() {
      this.modelStudent.studentContactHistoryDTOList.push({
        id: this.nextTodoIdHis += this.nextTodoIdHis,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItemContactHistory(index) {
      if (this.modelStudent.studentContactHistoryDTOList.length > 1) {
        this.modelStudent.studentContactHistoryDTOList.slice(index, 1)
        this.trTrimHeight(this.$refs.row[0].offsetHeight)
      }
    },
    importFileAttach(file) {
      this.isClickFile = true
      if (this.isClickFile === true) {
        this.listFileImport = this.listFileImport.concat(file)
      }
      const listArr = []
      if (this.listFileShowTable.length > 0 && this.idStudent) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < file.length; i++) {
          const sizeFile = file[i].size
          if (sizeFile <= 10485760) {
            listArr.push({ fileName: file[i].name, id: null })
          } else if (sizeFile > 10485760) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Lỗi tải file, ${file[i].name}`,
                icon: 'XCircleIcon',
                text: 'file tải lên không được vượt quá 1Mb',
                variant: 'danger',
              },
            })
          }
        }
        this.listFileShowTable = this.listFileShowTable.concat(listArr)
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < file.length; i++) {
          if (file[i].size <= 10485760) {
            listArr.push({ fileName: file[i].name, id: null })
          }
          if (file[i].size > 10485760) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Lỗi tải file, ${file[i].name}`,
                icon: 'XCircleIcon',
                text: 'file tải lên không được vượt quá 1Mb',
                variant: 'danger',
              },
            })
          }
        }
        this.listFileShowTable = this.listFileShowTable.concat(listArr)
      }
      if (this.listFileShowTable.length > 5) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Lỗi tải file',
            icon: 'XCircleIcon',
            text: 'File tải lên không được vượt quá 5 file',
            variant: 'danger',
          },
        })
        return
      }
      this.convertFileBase64()
    },
    convertFileBase64() {
      const arrFileEncode = []
      if (this.listFileImport.length > 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.listFileImport.length; i++) {
          const reader = new FileReader()
          if (this.listFileImport[i].size <= 1000141) {
            reader.readAsDataURL(this.listFileImport[i])
            reader.onload = () => {
              const base64 = reader.result
              arrFileEncode.push({ file: base64, fileName: this.listFileImport[i].name, id: '' })
            }
          }
          this.listPustFileEndcoedBase64 = arrFileEncode
        }
      }
    },
    getListDistrict() {
      const tt = this.cityOptions.find(x => x.value === this.modelStudent.province)
      if (this.modelStudent.province) {
        this.districtOptions = []
        this.subDistrictOptions = []
        this.modelStudent.district = null
        this.modelStudent.subDistrict = null
        this.districtOptions = province.getDistrict(this.modelStudent.province)
        this.modelStudent.address = tt.label
      } else {
        this.districtOptions = []
        this.subDistrictOptions = []
        this.modelStudent.district = null
        this.modelStudent.subDistrict = null
        this.modelStudent.address = ''
      }
    },
    getListSubDistrict() {
      const tt = this.cityOptions.find(x => x.value === this.modelStudent.province)
      const qh = this.districtOptions.find(x => x.value === this.modelStudent.district)
      if (this.modelStudent.district) {
        this.subDistrictOptions = []
        this.modelStudent.subDistrict = null
        this.subDistrictOptions = province.getSubDistrict(this.modelStudent.province, this.modelStudent.district)
        this.modelStudent.address = `${qh.label}, ${tt.label}`
      } else {
        this.subDistrictOptions = []
        this.modelStudent.subDistrict = null
        this.modelStudent.address = tt.label
      }
    },
    changeSubDistrict() {
      const tt = this.cityOptions.find(x => x.value === this.modelStudent.province)
      const qh = this.districtOptions.find(x => x.value === this.modelStudent.district)
      const xp = this.subDistrictOptions.find(x => x.value === this.modelStudent.subDistrict)
      if (this.modelStudent.subDistrict) {
        this.modelStudent.address = `${xp.label}, ${qh.label}, ${tt.label}`
      } else {
        this.modelStudent.address = `${qh.label}, ${tt.label}`
      }
    },
    changeAddress() {
      const tt = this.cityOptions.find(x => x.value === this.modelStudent.province)
      const qh = this.districtOptions.find(x => x.value === this.modelStudent.district)
      const xp = this.subDistrictOptions.find(x => x.value === this.modelStudent.subDistrict)
      if (this.modelStudent.houseNumber !== '' && this.modelStudent.province
          && this.modelStudent.district && this.modelStudent.subDistrict) {
        this.modelStudent.address = `${this.modelStudent.houseNumber}, ${xp.label}, ${qh.label}, ${tt.label}`
      } else if (this.modelStudent.province
          && this.modelStudent.district && this.modelStudent.subDistrict) {
        this.modelStudent.address = `${xp.label}, ${qh.label}, ${tt.label}`
      } else if (this.modelStudent.houseNumber !== '' && !this.modelStudent.province
          && !this.modelStudent.district && !this.modelStudent.subDistrict) {
        this.modelStudent.address = this.modelStudent.houseNumber
      } else if (this.modelStudent.houseNumber === '' && !this.modelStudent.province
          && !this.modelStudent.district && !this.modelStudent.subDistrict) {
        this.modelStudent.address = ''
      }
    },
    changeStatuss(status) {
      const newObj = {
        id: Math.floor(Math.random() * 100) + 10,
        text: status,
        value: Math.floor(Math.random() * 100) + 10,
      }
      this.statusOptions.push(newObj)
      this.modelStudent.status = status
    },
    addS() {
      // if (!this.images.file) {
      //   return this.$toast({
      //     component: ToastificationContent,
      //     position: 'top-right',
      //     props: {
      //       title: 'Lỗi',
      //       icon: 'XIcon',
      //       variant: 'danger',
      //       text: 'Ảnh hồ sơ là bắt buộc!',
      //     },
      //   }, {timeout: 2000})
      // }
      if (this.images.file) this.modelStudent.image = this.images
      if (!this.images.file) this.modelStudent.image = null
      this.modelStudent.promotionSchoolIds = this.selectedEvents
      const modelStudentClone = JSON.parse(JSON.stringify(this.modelStudent))
      modelStudentClone.familyOfStudentRegister.forEach(item => {
        if (item.birthDay) {
          // eslint-disable-next-line no-param-reassign
          item.birthDay = moment(item.birthDay).format('yyyy-MM-DDT00:00:00')
        }
      })
      if (modelStudentClone.birthDay) {
        modelStudentClone.birthDay = moment(modelStudentClone.birthDay)
          .format('yyyy-MM-DDT00:00:00')
      }
      if (modelStudentClone.startDate) {
        modelStudentClone.startDate = moment(modelStudentClone.startDate)
          .format('yyyy-MM-DDT00:00:00')
      }
      if (this.listPustFileEndcoedBase64.length > 0) {
        modelStudentClone.studentRegisterProfiles = this.listPustFileEndcoedBase64
      }
      modelStudentClone.promotionSchoolIds = this.selectedEvents
      this.$crm.post('guest/create', [modelStudentClone]).then(res => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Thành công!',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `Thêm mới hồ sơ ${res.data[0].fullName} thành công`,
          },
        }, { timeout: 4000 })
        this.$router.push({
          name: 'success',
        })
      })
    },
    addStudent() {
      this.$refs.modelStudent.validate().then(success => {
        if (success) {
          if (this.listPustFileEndcoedBase64.length > 5 || this.listFileShowTable.length > 5) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Lỗi',
                icon: 'XCircleIcon',
                variant: 'danger',
                text: 'Tối đa mỗi hồ sơ chỉ tải lên 5 file',
              },
            })
            return
          }
          let checkFather = !this.modelStudent.fatherName && !this.modelStudent.fatherEmail && !this.modelStudent.fatherNumber && !this.modelStudent.fatherBirthday && !this.modelStudent.fatherJob && !this.modelStudent.fatherJobPosition
          let checkMother = !this.modelStudent.motherName && !this.modelStudent.motherEmail && !this.modelStudent.motherNumber && !this.modelStudent.motherBirthday && !this.modelStudent.motherJob && !this.modelStudent.motherJobPosition
          let checkGuardian = !this.modelStudent.guardianName && !this.modelStudent.guardianEmail && !this.modelStudent.guardianNumber && !this.modelStudent.guardianBirthday && !this.modelStudent.guardianJob && !this.modelStudent.guardianJobPosition
          if (checkFather && checkMother && checkGuardian) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Lỗi',
                icon: 'XCircleIcon',
                variant: 'danger',
                text: 'Điền đầy đủ ít nhất thông tin bố, mẹ hoặc người giám hộ!',
              },
            })
            return
          }
          checkFather = !this.modelStudent.fatherName || !this.modelStudent.fatherEmail || !this.modelStudent.fatherNumber || !this.modelStudent.fatherBirthday || !this.modelStudent.fatherJob || !this.modelStudent.fatherJobPosition
          checkMother = !this.modelStudent.motherName || !this.modelStudent.motherEmail || !this.modelStudent.motherNumber || !this.modelStudent.motherBirthday || !this.modelStudent.motherJob || !this.modelStudent.motherJobPosition
          checkGuardian = !this.modelStudent.guardianName || !this.modelStudent.guardianEmail || !this.modelStudent.guardianNumber || !this.modelStudent.guardianBirthday || !this.modelStudent.guardianJob || !this.modelStudent.guardianJobPosition
          if (checkFather && checkMother && checkGuardian) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Lỗi',
                icon: 'XCircleIcon',
                variant: 'danger',
                text: 'Điền đầy đủ ít nhất thông tin bố, mẹ hoặc người giám hộ!',
              },
            })
            return
          }
          if (this.listPustFileEndcoedBase64.length <= 5 || this.listFileShowTable.length <= 5) {
            this.addS()
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Lỗi!',
              icon: 'XCircleIcon',
              variant: 'danger',
              text: 'Hãy nhập đầy đủ các trường bắt buộc!',
            },
          })
        }
      })
    },
    deletFileNoCallApi(item) {
      const index = this.listFileShowTable.indexOf(item)
      if (index > -1) {
        this.listFileShowTable.splice(index, 1)
        this.listPustFileEndcoedBase64.splice(index, 1)// 2nd parameter means remove one item only
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Thành công!',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Xóa file thành công',
          },
        })
      }
    },
    deleteFile(item) {
      this.$crm.post('student-register/delete-profile', { id: item.id }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Thành công!',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Xóa file thành công',
          },
        })
        const index = this.listFileShowTable.indexOf(item)
        this.listFileShowTable.splice(index, 1)
        this.listPustFileEndcoedBase64.splice(index, 1)
        this.listFileImport.splice(index, 1)
        this.convertFileBase64()
      })
    },
    downFileCallApi(item) {
      this.$crm.get(`file/${item.fileName}`)
        .then(res => {
          const accesstoken = localStorage.getItem('access_token')
          window.open(`${res.config.baseURL}${res.config.url}?access_token=${accesstoken}`, '_blank')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thành công',
              icon: 'CheckCircleIcon',
              text: 'Lấy file mẫu thành công',
            },
          })
        })
    },
    getPrice(val) {
      const classNum = parseInt(val.name.replace('Lớp ', ''), 10) - 1
      this.modelStudent.currentClass = classNum > 0 ? `Lớp ${classNum}` : null
      this.modelStudent.mathSemester1Score = null
      this.modelStudent.mathSemester2Score = null
      this.modelStudent.literatureSemester1Score = null
      this.modelStudent.literatureSemester2Score = null
      this.modelStudent.englishSemester1Score = null
      this.modelStudent.englishSemester2Score = null
      this.modelStudent.conduct = null
      const idClass = val.id
      if (idClass) {
        this.modelStudent.classId = idClass
        this.modelStudent.tuition = null
        this.tuitions = null
        this.$crm.get(`guest/find-tuition-by-class-id/${idClass}`).then(res => {
          if (res.data.id) {
            this.modelStudent.tuition = res.data.tuition ? res.data.tuition * res.data.totalMonth : null
            this.yearDiscount = res.data.yearDiscount
            this.semesterDiscount = res.data.semesterDiscount
            this.tuitions = res.data.tuition * res.data.totalMonth
          } else {
            this.modelStudent.tuition = null
            this.yearDiscount = null
            this.semesterDiscount = null
            this.tuitions = null
          }
        })
      }
    },
    selectedEvent() {
      const tuitionStudentRegisterDTO = {
        promotionSchoolIds: this.selectedEvents,
        semesterDiscount: this.semesterDiscount,
        tuition: Number(this.tuitions),
        tuitionType: this.modelStudent.tuitionType,
        yearDiscount: this.yearDiscount,
      }
      this.$crm.post('guest/get-tuition', tuitionStudentRegisterDTO).then(res => {
        if (res.data) {
          this.modelStudent.tuition = res.data.tuition
        }
      })
    },
    getGrade(val) {
      const classNum = val - 1
      this.modelStudent.currentClass = classNum > 0 ? `Lớp ${classNum}` : null
      this.modelStudent.mathSemester1Score = null
      this.modelStudent.mathSemester2Score = null
      this.modelStudent.literatureSemester1Score = null
      this.modelStudent.literatureSemester2Score = null
      this.modelStudent.englishSemester1Score = null
      this.modelStudent.englishSemester2Score = null
      this.modelStudent.conduct = null
    },
    getNextSaturdays(numSaturdays) {
      let result = [];
      let today = new Date();
      let currentDay = today.getDay(); // Lấy ngày trong tuần (0 là Chủ nhật, 1 là thứ Hai, ..., 6 là thứ Bảy)
      let daysUntilNextSaturday = 6 - currentDay; // Số ngày cần chờ đợi cho tới thứ Bảy tiếp theo

      // Thêm ngày thứ Bảy tiếp theo
      for (let i = 0; i < numSaturdays; i++) {
        let nextSaturday = new Date(today.getTime() + daysUntilNextSaturday * 24 * 60 * 60 * 1000).setHours(0,0,0,0); // Tính toán ngày thứ Bảy tiếp theo
        let date = {
          value: new Date(nextSaturday).toISOString(),
          label: 'Thứ 7, ngày ' + moment(nextSaturday).format('DD/MM/YYYY')
        }
        result.push(date);
        daysUntilNextSaturday += 7; // Bắt đầu tính toán cho ngày thứ Bảy tiếp theo
      }

      return result;
    },

    getFullName() {
      this.modelStudent.fullName = this.modelStudent.lastName + ' ' + this.modelStudent.firstName;
    },
    addEntryScore() {
      this.modelStudent.entryScoreList.push({
        id: null,
        entryDate: new Date(),
        entryEnglishScore: 0,
        entryInterviewScore: '', //Điểm tư duy/phỏng vấn
        entryLiteratureScore: 0,
        entryMathScore: 0,
      })
    },

    removeEntryScore(index) {
      this.modelStudent.entryScoreList.splice(index, 1)
    }
  },
}
</script>
<style lang="scss">
@import '../../@core/scss/vue/libs/vue-select';
@import '../../assets/scss/add-student';
@import '../../@core/scss/vue/libs/vue-flatpicker';
@import '../../@core/scss/vue/pages/page-auth';

.repeater-form {
  transition: .35s height;
}

#register-form {
  .taskbar_admissions {
    height: 60px;
    width: 100%;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    z-index: 50000;
    padding: 5px;
  }

}
</style>
